<template>
  <base-layout :loadingFlag="$store.state.loading" :show-home-link="false" :show-user-profile-link="false">
  <div>

    <router-view />
  </div>
  </base-layout>
</template>
<script>
import { mapState } from "vuex";
import { mapActions } from "vuex";

export default {
  components: {
    BaseLayout: () => import("@/components/BaseLayout.vue"),
  },
  computed: {
    ...mapState(["instanceConfig"])
  },
  methods: {
    ...mapActions(["fetchPaymentPortalInstanceConfig"]),
  },
  beforeRouteEnter(to, from, next) {
    let otpInfoString = sessionStorage.getItem('otp_info');
    let otpInfo;
    if (otpInfoString) {
      otpInfo = JSON.parse(otpInfoString);
    }

    if (otpInfo?.otpKey) {
      next()
    } else {
      next('/' +to.params.instanceId+ '/login')
    }

  }
};
</script>
